import { useContext } from 'react'
import { setSceduleDataApi } from '../api/api-requests'
import { LoanDetailsContext, OfferContext, ScheduleContext } from '../context'
import { TScheduleData } from '../types'

export const useGetScedule = () => {
  const { setLoanDetails } = useContext(LoanDetailsContext)

  const { setScheduleData, setActualInterestRate } = useContext(ScheduleContext)
  const { offers } = useContext(OfferContext)

  const setSceduleData = (loanDetails) => {
    const offer = offers[0]
    setLoanDetails(loanDetails)

    // setScheduleData([
    //   { baseAmount: 2000, date: `${new Date()}`, interestAmount: 24, totalAmount: 5000 }
    // ])

    setSceduleDataApi({
      duration: loanDetails.duration,
      amount: offer.maxAmount,
      rate: offer.interest,
      preferredRepaymentDay: loanDetails.preferredRepaymentDay
    }).then(({ data }: { data: TScheduleData }) => {
      if (data.repayments && data.repayments) {
        setScheduleData(data.repayments)
        setActualInterestRate(data.actualInterestRate)
      }
    })
  }

  return { setSceduleData }
}
