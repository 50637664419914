import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Text,
  FormContainer,
  FormField,
  Select,
  Input,
  WithFormFeedback,
  Checkbox,
  Heading,
  Icon,
  Tooltip
} from 'dino_ui_tools'
import { useSubmitApp } from '../../hooks'
import FilledInfo from '../FilledInfo'
import withWrapper from '../FormWrapper'
import { useGetProvinces, useProfile } from '../../hooks'
import { ADDRESS_VALIDATION_SCHEME, SUBVISION, REGION, STREET, BUILDING, UNIT } from './consts'
import FormSubmitButton from '../FormSubmitButton'
import Label from '../Label'
import { checkIsSameAddress } from './utils'
import './index.scss'
import { useParams } from 'react-router-dom'

const PersonalAddress = () => {
  const { profileInfo, updateProfileInfo } = useProfile()
  const [isSameAddress, setIsSameAddress] = useState(false)
  const { id } = useParams()

  const residenceAddress = useMemo(() => {
    return profileInfo && profileInfo.residenceAddress
      ? profileInfo.residenceAddress
      : { subdivisionCode: '', regionCode: '', street: '', building: '', unit: '' }
  }, [profileInfo])

  const registartionAddress = useMemo(() => {
    return profileInfo && profileInfo.registrationAddress
      ? profileInfo.registrationAddress
      : {
          subdivisionCode: '',
          regionCode: '',
          street: '',
          building: '',
          unit: ''
        }
  }, [profileInfo])

  const { submitCandidateApplication } = useSubmitApp()
  const { subdivisionCode, regionCode, street, building, unit } = residenceAddress

  const { provinces, regions, getRegions, getProvinces } = useGetProvinces()

  const findProvinceWithValue = useCallback(
    (subdivision) => {
      const found = provinces.find((province) => province.value === subdivision)
      return found ? found : { label: '' }
    },
    [provinces]
  )

  const findRegionWithValue = useCallback(
    (regionCode_) => {
      const found = regions.find((region) => region.value === regionCode_)
      return found ? found : { label: '' }
    },
    [regions]
  )

  const ADDRESS_INITIAL_VALUES = {
    [SUBVISION]: findProvinceWithValue(subdivisionCode),
    [REGION]: findRegionWithValue(regionCode),
    [STREET]: street,
    [BUILDING]: building,
    [UNIT]: unit
  }

  const onAdressSubmit = (data) =>
    updateProfileInfo(
      {
        ...profileInfo,
        residenceAddress: {
          countryCode: 'AM',
          ...data
        }
      },
      id,
      submitCandidateApplication,
      true
    )

  const keepRegAddress = () => onAdressSubmit(registartionAddress)

  useEffect(() => {
    if (provinces.length === 0) {
      getProvinces()
    }
  }, [])

  useEffect(() => {
    const isSame = checkIsSameAddress(registartionAddress, residenceAddress)
    setIsSameAddress(isSame)
  }, [])

  if (subdivisionCode && provinces.length === 0) {
    return null
  }

  const isStreetLong = registartionAddress.street.length > 13

  return (
    <div className="personal_address_container">
      <Heading size="xsmall" className="mb-16">
        Գրանցման հասցե
      </Heading>
      <div className="loan_filled_info_container">
        <FilledInfo label="Երկիր" info="Հայաստան" />
        <FilledInfo
          label="Մարզ"
          info={findProvinceWithValue(registartionAddress.subdivisionCode).label}
        />
      </div>
      <div className="loan_filled_info_container">
        <FilledInfo
          label="Բնակավայր"
          info={findRegionWithValue(registartionAddress.regionCode).label}
        />
        <div className="overflowWrapper">
          <FilledInfo label="Փողոց" info={registartionAddress.street} />
          {isStreetLong ? (
            <Tooltip text={registartionAddress.street} position="top-right">
              <Icon name="info" />
            </Tooltip>
          ) : null}
        </div>
      </div>
      <div className="loan_filled_info_container">
        <FilledInfo label="Շենք/Տուն" info={registartionAddress.building} />
        <FilledInfo label="Բնակարան" info={registartionAddress.unit} />
      </div>

      <Checkbox
        label={<Text size="small">Գրանցման և բնակության հասցեները համընկնում են:</Text>}
        selectedValue={isSameAddress}
        onClick={setIsSameAddress}
      />
      {!isSameAddress ? (
        <>
          <Text size="large" className="personal_address_container_title">
            Բնակության հասցե
          </Text>
          <FilledInfo label="Երկիր" info="Հայաստան" className="mb-24" />
          <FormContainer
            validationScheme={ADDRESS_VALIDATION_SCHEME}
            initialValues={ADDRESS_INITIAL_VALUES}
            onSubmit={onAdressSubmit}
          >
            <>
              <WithFormFeedback>
                {({ setValue, getValues }) => {
                  return (
                    <>
                      <FormField
                        className="mb-24"
                        isControlled
                        isNeedChangeHandler
                        name={SUBVISION}
                        As={(props) => (
                          <Select
                            {...props}
                            label={<Label text="Մարզ" required />}
                            options={provinces}
                            setSelectedItem={(selected) => {
                              getRegions(selected.toString())
                              setValue(REGION, '')
                            }}
                          />
                        )}
                      />
                      <FormField
                        className="mb-24"
                        isControlled
                        isNeedChangeHandler
                        name={REGION}
                        As={(props) => (
                          <Select
                            {...props}
                            key={getValues()[REGION]}
                            label={<Label text="Բնակավայր" required />}
                            options={regions}
                          />
                        )}
                      />
                    </>
                  )
                }}
              </WithFormFeedback>
              <FormField
                className="mb-24"
                name={STREET}
                As={(props) => <Input {...props} label={<Label text="Փողոց" required />} />}
              />
              <FormField
                className="mb-24"
                name={BUILDING}
                As={(props) => <Input {...props} label={<Label text="Շենք/Տուն" required />} />}
              />
              <FormField
                className="mb-24"
                name={UNIT}
                As={(props) => <Input {...props} label={<Label text="Բնակարան" />} />}
              />
              <FormSubmitButton />
            </>
          </FormContainer>
        </>
      ) : (
        <FormSubmitButton onClick={keepRegAddress} />
      )}
    </div>
  )
}
export default withWrapper(PersonalAddress)
