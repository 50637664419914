import * as yup from 'yup'
import { VALIDATION_MESSAGES } from '../../consts'

export const PHONE_NUMBER = 'phoneNumber'
export const VERIFICATION_CODE = 'verificationCode'

export const VERIFICATION_VALIDATION = yup
  .string()
  .required(VALIDATION_MESSAGES.required)
  .test('valid', VALIDATION_MESSAGES.invalid, (val) => val?.indexOf('_') === -1)

export const PHONE_NUMBER_VALIDATION_SCHEME = yup
  .string()
  .required(VALIDATION_MESSAGES.required)
  .test('valid', VALIDATION_MESSAGES.invalid, (val) => val?.indexOf('_') === -1)
