import { useContext, useEffect } from 'react'
import moment from 'moment'
import { Checkbox, FormContainer, FormField, Heading, Select, Text } from 'dino_ui_tools'

import { useGetAppOffer, useGetShoppingDetails, useProfile, useSubmitLoanterms } from '../../hooks'
import { LOAN_DETAILS_LINK, LOAN_MAIN_DETAILS_LINK } from '../../consts'
import { FormStepperContext, OfferContext, ScheduleContext } from '../../context'

import { formatPhoneNumber } from '../PersonalDetails/utils'
import FormSubmitButton from '../FormSubmitButton'
import withWrapper from '../FormWrapper'
import FilledInfo from '../FilledInfo'

import LoanDetails from './LoanDetails'
import {
  LOAN_CONDITION_1,
  LOAN_CONDITION_2,
  LOAN_CONDITION_VALIDATION_SCHEME,
  LOAN_CONDITION_INITIAL_VALUES,
  EXTRACT_OPTIONS,
  STATMENT_DELIVERY_METHOD
} from './consts'

import { TShoppingDetail } from '../../types'
import './index.scss'

const LoanConfirmation = () => {
  const { shoppingData, getShoppingDetails } = useGetShoppingDetails()
  const { scheduleData } = useContext(ScheduleContext)
  const { profileInfo, getProfile } = useProfile()
  const { offers } = useContext(OfferContext)
  const { nextHandler } = useContext(FormStepperContext)
  const { getAppOffers } = useGetAppOffer()
  const { date, totalAmount } = scheduleData[0]

  const offer = offers[0]

  const { name, lastname, patronymic, contactPhone, contactEmail, identificationDocument } =
    profileInfo

  const { setLoanTermsForSubmit } = useSubmitLoanterms()

  const handleSubmit = (data) => {
    setLoanTermsForSubmit(data)
    nextHandler()
  }

  useEffect(() => {
    getProfile()
    getShoppingDetails()
  }, [])

  useEffect(() => {
    if (offers.length === 0) {
      getAppOffers()
    }
  }, [])

  if (!offer || !profileInfo) {
    return null
  }

  return (
    <div className="loan_confirmation">
      <Heading size="xsmall" className="mb-24">
        Գնորդի անձնական տվյալներ
      </Heading>

      <FilledInfo info={`${name} ${lastname} ${patronymic}`} className="withBottomMargin" />

      <div className="loan_confirmation_profile_info_container">
        <FilledInfo
          label="Փաստաթուղթ"
          info={identificationDocument.number}
          className="withBottomMargin"
        />
        <FilledInfo
          label="Հեռախոսահամար"
          info={formatPhoneNumber(contactPhone)}
          className="withBottomMargin"
        />
        {contactEmail && contactEmail.emailAddress ? (
          <FilledInfo
            label="Էլ. հասցե"
            info={contactEmail.emailAddress}
            className="withBottomMargin"
          />
        ) : null}
      </div>

      <Text size="large" className="mb-24">
        Գնվող ապրանքատեսականին
      </Text>
      {shoppingData.map((shoppingItem: TShoppingDetail, index: number) => {
        const isLastItem = index === shoppingData.length - 1
        return (
          <div className="loan_confirmation_profile_info_container">
            <FilledInfo
              label="Անվանում"
              info={shoppingItem.name}
              className="withBottomMargin halfWidth"
            />
            <FilledInfo
              label="Քանակ"
              info={shoppingItem.count.toString()}
              className="withBottomMargin halfWidth"
            />

            <FilledInfo
              label="Արժեք"
              info={shoppingItem.totalPrice}
              className={isLastItem ? 'withBottomMargin' : ''}
            />

            {!isLastItem ? <div className="loan_confirmation_divider" /> : null}
          </div>
        )
      })}

      <Heading size="xsmall" className="mb-24">
        Ապառիկի տվյալներ
      </Heading>
      <div className="loan_confirmation_info_container">
        <FilledInfo
          weight="bolder"
          label="Ընդհանուր գումար"
          info={`${offer.maxAmount} ${offer.currencyCode}`}
        />
        <FilledInfo weight="bolder" label="Տոկոսադրույք" info={`${offer.interest}%`} />
      </div>

      <FormContainer
        onSubmit={handleSubmit}
        validationScheme={LOAN_CONDITION_VALIDATION_SCHEME}
        initialValues={LOAN_CONDITION_INITIAL_VALUES}
      >
        <>
          <LoanDetails />

          <div className="loan_confirmation_info_container">
            {date ? (
              <FilledInfo
                weight="bolder"
                label="Առաջին վճարման ամաթիվ"
                info={moment(date).format('DD.MM.YYYY').toString()}
              />
            ) : null}
            {totalAmount ? (
              <FilledInfo
                weight="bolder"
                label="Ամենամսյա վճարման չափ"
                info={totalAmount.toString()}
              />
            ) : null}
          </div>

          <FormField
            className="mb-24"
            isControlled
            isNeedChangeHandler
            name={STATMENT_DELIVERY_METHOD}
            As={(props) => (
              <Select
                {...props}
                isRequiredField
                options={EXTRACT_OPTIONS}
                label="Քաղվածքների ստացման տարբերակ"
              />
            )}
          />
          <FormField
            className="mb-24"
            isControlled
            isNeedChangeHandler
            name={LOAN_CONDITION_1}
            As={(props) => (
              <Checkbox
                {...props}
                label={
                  <Text>
                    <>
                      {`Ես ծանոթացել և համաձայն եմ `}
                      <a
                        className="loan_details_link"
                        href={LOAN_DETAILS_LINK}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Ապառիկ ֆինանսավորման ծառայության մատուցման հիմնական պայմաններին
                      </a>
                      {` և `}
                      <a
                        className="loan_details_link"
                        href={LOAN_MAIN_DETAILS_LINK}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {` ֆինանսավորման պայմաններին`}
                      </a>
                    </>
                  </Text>
                }
              />
            )}
          />
          <FormField
            className="mb-24"
            isControlled
            isNeedChangeHandler
            name={LOAN_CONDITION_2}
            As={(props) => (
              <Checkbox
                {...props}
                label={<Text>Համաձայն եմ նշված պայմաններով սույն Օֆերտան բանկ ուղարկելուն</Text>}
              />
            )}
          />
          <FormSubmitButton buttonText="Հաստատել" />
        </>
      </FormContainer>
    </div>
  )
}

export default withWrapper(LoanConfirmation)
