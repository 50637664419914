import { useContext } from 'react'
import { OFFERS_PREPARING_MESSAGE } from '../consts'
import { LoadingContext } from '../context'
import { useCheckAppStatus } from './useCheckAppStatus'
import { useInterval } from './useInterval'

export const useSubmitApp = () => {
  const { setLoadingMessage, setIsLoading, isLoading } = useContext(LoadingContext)

  const { checkLoanStatus } = useCheckAppStatus()

  const appCreationCallback = () => {
    abort()
    setLoadingMessage('')
    setIsLoading(false)
  }
  const checkAppStatus = () => {
    checkLoanStatus(appCreationCallback)
  }

  const { abort } = useInterval(checkAppStatus, isLoading ? 1000 : 0)

  const submitCandidateApplication = () => {
    setLoadingMessage(OFFERS_PREPARING_MESSAGE)
  }

  return { submitCandidateApplication }
}
