import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getRedirectUrlApi } from '../api/api-requests'

export const useGetRedirectUrl = () => {
  const [redirectUrl, setRedirectUrl] = useState('')

  const { id } = useParams()
  useEffect(() => {
    getRedirectUrlApi(id).then(({ data }: { data: string }) => setRedirectUrl(data))
  }, [])
  return { redirectUrl }
}
