import { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { checkAppStatusApi } from '../api/api-requests'
import {
  DECLINED_STATES,
  DOCUMENTS_VERIFIED_STATUSES,
  LOADING_STATES,
  LOAN_STATUSES,
  OFFERS_PREPARING_MESSAGE
} from '../consts'
import application_statuses from '../consts/application_statuses.json'
import { ActiveStepContext, AppContext, AppStatusContext, LoadingContext } from '../context'

const statusPageMapping = {
  new: '0',
  contactVerified: '1',
  documentVerified: '2',
  identified: '3',
  offersPreparing: '3',
  rejected: '6',
  offersReady: '4',
  termsAccepted: '6',
  failed: '6',
  signaturePending: '6',
  canceled: '6',
  completed: '6'
}

export const useCheckAppStatus = () => {
  const { id } = useParams()
  const { setIsDocumentVerified } = useContext(AppContext)
  const { setActiveStepIndex } = useContext(ActiveStepContext)
  const { setStatus, setReason } = useContext(AppStatusContext)

  const { setIsLoading, setLoadingMessage } = useContext(LoadingContext)

  const checkLoanStatus = (callBack?: () => void) => {
    setIsLoading(true)
    checkAppStatusApi(id).then(({ data }) => {
      const { currentState, labels } = data

      if (DECLINED_STATES.indexOf(currentState) !== -1) {
        if (labels && labels[0]) {
          const reason = labels[0].value
          setReason(reason)
        }
      }
      if (DOCUMENTS_VERIFIED_STATUSES.indexOf(currentState) !== -1) {
        setIsDocumentVerified(true)
      }
      if (currentState === LOAN_STATUSES.offersPreparing) {
        setLoadingMessage(OFFERS_PREPARING_MESSAGE)
      }
      setActiveStepIndex(statusPageMapping[application_statuses[currentState]])

      setStatus(application_statuses[currentState])
      if (LOADING_STATES.indexOf(currentState) !== -1) {
        return
      }

      setIsLoading(false)

      if (callBack) {
        callBack()
      }
    })
  }

  return { checkLoanStatus }
}
