import { Button, Container, Icon, Text } from 'dino_ui_tools'
import { useContext, useEffect } from 'react'
import { AppStatusContext } from '../../context'
import { useGetRedirectUrl } from '../../hooks'
import RejectedResultData from '../../consts/status_data.json'
import './index.scss'

const INFO = {
  rejected: {
    iconName: 'close',
    iconType: 'danger',
    showReason: true,
    message: 'Ձեր կողմից ուղարկված օֆերտան մերժվել է'
  },
  completed: {
    iconName: 'mark',
    iconType: 'success',
    message: `Հարգելի' գործընկեր, գործողությունը Ձեր կողմից արդեն իսկ կատարվել է:`
  },
  canceled: {
    iconName: 'close',
    iconType: 'danger',
    hasBackToShopButton: true,
    message: 'Ուղարկված Օֆերտան Ձեր կողմից չի հաստատվել'
  },
  signaturePending: {
    iconName: 'mark',
    iconType: 'success',
    title: 'Շնորհակալություն',
    hasBackToShopButton: true,
    message:
      'Ձեր կողմից կատարված գնման ֆինանսավորումը հաստատված է։ Էլ. փոստի հասցեին կուղարկվի Ձեր կողմից  ներկայացված Օֆերտան և փաստաթղթերի փաթեթը հաատատման'
  },
  termsAccepted: {
    iconName: 'mark',
    iconType: 'success',
    title: 'Շնորհավորում ենք',
    hasBackToShopButton: true,
    message: `Ձեր կողմից կատարված գնման ֆինանսավորումը հաստատված է։ Խնդրում ենք ստուգել Էլ. փոստի հասցեն, որին կուղարկվի Ձեր կողմից  ներկայացված Օֆերտան և փաստաթղթերի փաթեթը հաատատման`
  },
  failed: {
    iconName: 'close',
    iconType: 'danger',
    message:
      'Տեխնիկական խնդրի պատճառով հայտի մշակումը խափանվել է: Խնդրում ենք փորձել նորից, կամ դիմել բանկ'
  }
}
const EmailFallbackView = () => {
  const { status, reason } = useContext(AppStatusContext)

  const { title, iconName, iconType, message, hasBackToShopButton, showReason } = INFO[status]
  const { redirectUrl } = useGetRedirectUrl()

  const backToEcomerce = () => {
    window.open(redirectUrl, '_self')
  }

  useEffect(() => {
    if (hasBackToShopButton && redirectUrl) {
      setTimeout(backToEcomerce, 10000)
    }
  }, [redirectUrl, hasBackToShopButton])

  return (
    <div className="congratulation_container">
      <Container>
        <div className="congratulation_container_content">
          <Icon name={iconName} size="large" className="icon-wrapper" type={iconType} />
          <Text className="congratulation_container_title" size="medium" weight="bold">
            {title}
          </Text>
          <Text className="congratulation_container_info_text" size="medium">
            {message}
          </Text>
          {reason && showReason ? (
            // TODO check this
            <Text size="small">
              <>Մերժման պատճառը` {RejectedResultData[reason]}</>
            </Text>
          ) : null}

          {hasBackToShopButton ? (
            <Button
              buttonText="Վերադարձ խանութ"
              type="primary"
              onClick={backToEcomerce}
              className="final_result_back_button"
            />
          ) : null}
        </div>
      </Container>
    </div>
  )
}

export default EmailFallbackView
