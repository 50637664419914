import { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { getShoppingDetailsApi } from '../api/api-requests'
import { ShoppingDataContext } from '../context'
import { TShoppingData } from '../types'

export const useGetShoppingDetails = (callback?: (result: TShoppingData) => void) => {
  const { shoppingData, setShoppingData } = useContext(ShoppingDataContext)
  const { id } = useParams()

  const getShoppingDetails = () => {
    getShoppingDetailsApi(id).then(({ data }) => {
      if (data.cart && data.cart.items && data.cart.items.length) {
        setShoppingData(data.cart.items)
        callback && callback(data)
      }
    })
  }

  return { shoppingData, getShoppingDetails }
}
