import { ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import {
  getProfileApi,
  updateProfileInfoApi,
  getProvincesApi,
  getRegionsApi,
  getAppConfigsApi
} from '../api/api-requests'
import { GLOBAL_ERROR_MESSAGE, LOAN_STATUSES, REPAYMENTS_MOCK_DATA } from '../consts'
import { TLocalizationsDataAPI, TProfileInfo, TTermsData } from '../types'
import { noop } from '../utils'
import {
  ProfileContext,
  LoadingContext,
  ProvincesContext,
  AppContext,
  LoanDetailsContext,
  AppConfigContext,
  CONFIGS_DEFAULT_VALUE,
  AppStatusContext,
  UserNameContext,
  InfoPopupVisibilityContext,
  ErrorContext,
  ShoppingDataContext,
  ScheduleContext,
  OfferContext,
  ActiveStepContext,
  LaonTermsContext
} from './index'
import { Loader, ErrorPopup } from '../components'

export const UserNameContextProvider = ({ children }: { children?: ReactNode }) => {
  const [userName, setUserName] = useState('')
  return (
    <UserNameContext.Provider value={{ userName, setUserName }}>
      {children}
    </UserNameContext.Provider>
  )
}

export const ProfileContextProvider = (props) => {
  const [profileInfo, setProfileDate] = useState<TProfileInfo>(null)

  const { children } = props
  const { setErrorMessage } = useContext(ErrorContext)
  const getProfile = (callback: (data: TProfileInfo) => void = noop) => {
    getProfileApi().then(({ data }: { data: TProfileInfo }) => {
      setProfileDate(data)

      if (callback) {
        callback(data)
      }
    })
  }
  const { setIsLoading } = useContext(LoadingContext)
  const updateProfileInfo = (
    data: TProfileInfo,
    id: string,
    callback: (data: TProfileInfo) => void = noop,
    withLoading = false
  ) => {
    if (withLoading) {
      setIsLoading(true)
    }
    updateProfileInfoApi(id, data)
      .then(() => {
        getProfile(callback)
      })
      .catch(() => {
        setIsLoading(false)
        setErrorMessage(GLOBAL_ERROR_MESSAGE)
      })
  }

  return (
    <ProfileContext.Provider value={{ profileInfo, getProfile, updateProfileInfo }}>
      {children}
    </ProfileContext.Provider>
  )
}

export const LoadingContextProvider = (props) => {
  const { children } = props
  const [isLoading, setIsLoading] = useState(false)
  const [message, setLoadingMessage] = useState('')

  return (
    <LoadingContext.Provider value={{ isLoading, setIsLoading, message, setLoadingMessage }}>
      <>
        {isLoading ? <Loader message={message} /> : null}
        {children}
      </>
    </LoadingContext.Provider>
  )
}

export const ProvincesContextProvider = ({ children }: { children?: ReactNode }) => {
  const [provincesData, setProvinces] = useState([])
  const [regionsData, setRegions] = useState([])
  const getRegions = (provinceCode: string) =>
    getRegionsApi(provinceCode).then(({ data }: { data: TLocalizationsDataAPI }) => {
      setRegions(data)
    })

  const getProvinces = () =>
    getProvincesApi().then(({ data }: { data: TLocalizationsDataAPI }) => setProvinces(data))

  const provinces = useMemo(() => {
    return provincesData.map((province) => {
      return {
        label: province.value,
        value: province.code
      }
    })
  }, [provincesData])

  const regions = useMemo(() => {
    return regionsData.map((region) => {
      return {
        label: region.value,
        value: region.code
      }
    })
  }, [regionsData])

  return (
    <ProvincesContext.Provider value={{ provinces, regions, getRegions, getProvinces }}>
      {children}
    </ProvincesContext.Provider>
  )
}

export const AppIdContextProvider = ({ children }) => {
  const [appId, setAppId] = useState(null)
  const [isDocumentsVerified, setIsDocumentVerified] = useState(false)

  return (
    <AppContext.Provider value={{ appId, setAppId, isDocumentsVerified, setIsDocumentVerified }}>
      {children}
    </AppContext.Provider>
  )
}

export const LoanDetailsProvider = ({ children }) => {
  const [loanDetails, setLoanDetails] = useState({
    preferredRepaymentDay: null,
    duration: null,
    preferredRepaymentDate: null
  })

  return (
    <LoanDetailsContext.Provider value={{ loanDetails, setLoanDetails }}>
      {children}
    </LoanDetailsContext.Provider>
  )
}

export const AppConfigContextProvider = ({ children }: { children?: ReactNode }) => {
  const [configs, setConfigs] = useState(CONFIGS_DEFAULT_VALUE)

  useEffect(() => {
    getAppConfigsApi().then(({ data }) => setConfigs(data))
  }, [])

  return <AppConfigContext.Provider value={{ ...configs }}>{children}</AppConfigContext.Provider>
}
export const AppStatusContextProvider = ({ children }: { children?: ReactNode }) => {
  const [status, setStatus] = useState(LOAN_STATUSES.new)
  const [reason, setReason] = useState('')

  return (
    <AppStatusContext.Provider value={{ status, setStatus, setReason, reason }}>
      {children}
    </AppStatusContext.Provider>
  )
}

export const InfoPopupVisibilityContextProvider = ({ children }) => {
  const [isVisible, setIsVisible] = useState(true)

  const closePopup = () => setIsVisible(false)
  return (
    <InfoPopupVisibilityContext.Provider value={{ isVisible, closePopup }}>
      {children}
    </InfoPopupVisibilityContext.Provider>
  )
}

export const ErrorContextProvider = ({ children }: { children?: ReactNode }) => {
  const [errorMessage, setErrorMessage] = useState('')
  const closePopup = () => setErrorMessage('')

  return (
    <ErrorContext.Provider value={{ errorMessage, setErrorMessage }}>
      <ErrorPopup errorMessage={errorMessage} closePopup={closePopup} />
      {children}
    </ErrorContext.Provider>
  )
}
export const ShoppingDataContextprovider = ({ children }) => {
  const [shoppingData, setShoppingData] = useState([])

  return (
    <ShoppingDataContext.Provider value={{ shoppingData, setShoppingData }}>
      {children}
    </ShoppingDataContext.Provider>
  )
}
export const ScheduleContextProvider = ({ children }) => {
  const [scheduleData, setScheduleData] = useState(REPAYMENTS_MOCK_DATA)
  const [actualInterestRate, setActualInterestRate] = useState(null)

  return (
    <ScheduleContext.Provider
      value={{ scheduleData, setScheduleData, actualInterestRate, setActualInterestRate }}
    >
      {children}
    </ScheduleContext.Provider>
  )
}

export const OfferContextProvider = ({ children }) => {
  const [offers, setOffers] = useState([])

  return <OfferContext.Provider value={{ offers, setOffers }}>{children}</OfferContext.Provider>
}

export const ActiveStepContextProvider = ({ children }: { children?: ReactNode }) => {
  const [activeStepIndex, setActiveStepIndex] = useState(0)
  const setter = (step) => {
    setActiveStepIndex(step)
  }

  return (
    <ActiveStepContext.Provider value={{ activeStepIndex, setActiveStepIndex: setter }}>
      {children}
    </ActiveStepContext.Provider>
  )
}

export const LaonTermsContextProvider = ({ children }: { children?: ReactNode }) => {
  const [data, setData] = useState<TTermsData>(null)
  const setter = (_data) => {
    setData(_data)
  }

  return (
    <LaonTermsContext.Provider value={{ data, setData: setter }}>
      {children}
    </LaonTermsContext.Provider>
  )
}
