import { useContext, useState } from 'react'
import { ErrorContext, LoadingContext } from '../context'
import { useCheckAppStatus } from './useCheckAppStatus'
import { useInterval } from './useInterval'

export const useSubmitDocuments = () => {
  const { setLoadingMessage } = useContext(LoadingContext)
  const [isAppStatusChecking, setIsAppStatusChecking] = useState(false)

  const { checkLoanStatus } = useCheckAppStatus()
  const { setErrorMessage } = useContext(ErrorContext)

  const appCreationCallback = () => {
    abort()
    setIsAppStatusChecking(false)
    setLoadingMessage('')
  }
  const checkAppStatus = () => {
    checkLoanStatus(appCreationCallback)
  }
  const { abort } = useInterval(checkAppStatus, isAppStatusChecking ? 1000 : 0)

  const submitDocuments = (data) => {
    if (!data.identificationDocument.isVerified) {
      setErrorMessage('Տվյալների անհամապատասխանություն')
    }
    setIsAppStatusChecking(true)
  }

  return { submitDocuments }
}
