import * as yup from 'yup'
export const LOAN_CONDITION_1 = 'loan_contitions_1'
export const LOAN_CONDITION_2 = 'loan_contitions_2'
export const STATMENT_DELIVERY_METHOD = 'statementDeliveryMethod'

import { VALIDATION_MESSAGES } from '../../consts'

export const PAYMENT_DAY = 'preferredRepaymentDay'
export const LOAN_DURATION = 'duration'

export const EXTRACT_OPTIONS = [
  { label: 'Էլեկտրոնային հասցեով', value: 'email' },
  { label: 'Փոստով', value: 'post' }
]

export const LOAN_CONDITION_VALIDATION_SCHEME = yup.object({
  [LOAN_CONDITION_1]: yup.boolean().oneOf([true], 'Ծանոթացեք վարկային պայմաններին'),
  [LOAN_CONDITION_2]: yup.boolean().oneOf([true], 'Ծանոթացեք վարկային պայմաններին'),
  [STATMENT_DELIVERY_METHOD]: yup.string().required(),
  [PAYMENT_DAY]: yup
    .date()
    .required(VALIDATION_MESSAGES.required)
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr)),
  [LOAN_DURATION]: yup.string().required(VALIDATION_MESSAGES.required)
})

export const LOAN_CONDITION_INITIAL_VALUES = {
  [LOAN_CONDITION_1]: false,
  [LOAN_CONDITION_2]: false,
  [STATMENT_DELIVERY_METHOD]: EXTRACT_OPTIONS[0]
}
