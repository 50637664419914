import { Container, Heading } from 'dino_ui_tools'
import FormFooter from './FormFooter'
import FormHeader from './FormHeader'
import { FormStepperContext } from '../../context'

import { TFormWrapperProps } from './types'
import './index.scss'
import { useIsMobile } from '../../hooks/useIsMobile'
import { ReactNode } from 'react'
// import { LoadingContextProvider } from '../../context/contextProviders'

const withWrapper = (Comp: (props) => ReactNode) => (props: TFormWrapperProps) => {
  const { activeStepIndex, prevHandler, nextHandler } = props

  const isMobile = useIsMobile()

  return (
    <div className="form_wrapper">
      <FormStepperContext.Provider value={{ activeStepIndex, prevHandler, nextHandler }}>
        <Heading
          className="form_wrapper_title mb-24"
          size={isMobile ? 'xsmall' : 'small'}
          weight="bold"
        >
          Ապառիկ ֆինանսավորման օնլայն հարթակ
        </Heading>

        <FormHeader />
        {isMobile ? (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <Comp />
        ) : (
          <Container>
            {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore */}
            <Comp />
          </Container>
        )}
        <FormFooter />
      </FormStepperContext.Provider>
    </div>
  )
}

export default withWrapper
