import { useContext, useEffect } from 'react'
import { getSession } from '../api/api-requests'
import { UserNameContext } from '../context'
import { TSessionInfo } from '../types'

export const useSetSession = () => {
  const { setUserName } = useContext(UserNameContext)

  useEffect(() => {
    getSession().then(({ data }: { data: TSessionInfo }) => {
      setUserName(data && data.user && data.user.username)
    })
  }, [])

  return null
}
