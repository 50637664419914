import { useContext, useEffect } from 'react'
import { Stepper } from 'dino_ui_tools'
import {
  PersonalDetails,
  PersonalData,
  Identification,
  PersonalAddress,
  LoanConfirmation,
  EmailFallbackView,
  ConfirmationView
} from '../../components'
import { useGetProvinces, useProfile, useSetSession } from '../../hooks'
import { InfoPopupVisibilityContextProvider } from '../../context/contextProviders'
import { ActiveStepContext, AppStatusContext } from '../../context'
import { useCheckAppStatus } from '../../hooks/useCheckAppStatus'
import { LOADING_STATES, LOAN_STATUSES } from '../../consts'
import { useInterval } from '../../hooks/useInterval'

const PROFILE_STEPS = [
  PersonalDetails,
  PersonalData,
  Identification,
  PersonalAddress,
  LoanConfirmation,
  ConfirmationView,
  EmailFallbackView
]

const FormScreen = () => {
  useSetSession()
  const { checkLoanStatus } = useCheckAppStatus()
  const { status } = useContext(AppStatusContext)
  const { activeStepIndex, setActiveStepIndex } = useContext(ActiveStepContext)
  const { getProvinces, getRegions, provinces, regions } = useGetProvinces()

  const { profileInfo, getProfile } = useProfile()
  const { residenceAddress } = profileInfo || {}

  const { subdivisionCode } = residenceAddress || {}

  useEffect(() => {
    if (subdivisionCode) {
      getProvinces()
      getRegions(subdivisionCode)
    }
  }, [subdivisionCode])

  useEffect(() => {
    getProfile()
    checkLoanStatus()
  }, [])

  const { abort } = useInterval(
    () => checkLoanStatus(abort),
    LOADING_STATES.indexOf(LOAN_STATUSES[status]) !== -1 ? 1000 : 0
  )

  if (profileInfo === null) {
    return
  }

  if (subdivisionCode && (provinces.length === 0 || regions.length === 0)) {
    return null
  }

  return (
    <InfoPopupVisibilityContextProvider>
      <Stepper
        list={PROFILE_STEPS}
        activeStep={activeStepIndex}
        setActiveStep={setActiveStepIndex}
      />
    </InfoPopupVisibilityContextProvider>
  )
}
export default FormScreen
