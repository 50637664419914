import { useCallback, useEffect, useRef } from 'react'
import { Text } from 'dino_ui_tools'
import { TCountDownProps } from './types'
import './index.scss'

const ONE_MINUTE = 60

const CountDown = (props: TCountDownProps) => {
  const { currentSecond, currentMinute, setCurrentMinute, setCurrentSecond, onComplete } = props

  const intervalId = useRef(null)

  const decrementState = useCallback(() => {
    if (currentMinute <= 0 && currentSecond <= 0) {
      clearInterval(intervalId.current)
      onComplete()
      return
    }

    if (currentSecond < 0) {
      setCurrentMinute(currentMinute - 1)
      setCurrentSecond(ONE_MINUTE)
      return
    }

    setCurrentSecond(currentSecond - 1)
  }, [currentMinute, currentSecond])

  useEffect(() => {
    intervalId.current = setInterval(decrementState, 1000)
    return () => {
      clearInterval(intervalId.current)
    }
  }, [decrementState])

  if (currentSecond === 0 && currentMinute === 0) {
    return null
  }
  return (
    <div className="countdown_container">
      <Text size="xsmall" className="message">
        Կոդը վավեր է`
      </Text>
      <Text size="medium" className="minutes">
        {`${currentMinute > 9 ? currentMinute : `0${currentMinute}`} : ${
          currentSecond > 9 ? currentSecond : `0${currentSecond}`
        }`}
      </Text>
    </div>
  )
}

export default CountDown
