import { useEffect, useState, useRef, useCallback } from 'react'
import { TEventType } from '../..'
import { MOBILE_SIZE } from '../consts'
import { isEmptyObject } from '../utils'

export const useEventListener = (
  eventName: string,
  handler: (event: any) => void,
  element: HTMLElement | null | Window = window
): void => {
  const savedHandler = useRef(handler)

  useEffect(() => {
    savedHandler.current = handler
  }, [handler, savedHandler])

  useEffect(() => {
    const isSupported = element && element.addEventListener
    if (!isSupported) return

    const eventListener = (event: TEventType) => savedHandler.current(event)

    element.addEventListener(eventName, eventListener as EventListener)
    return () => {
      element.removeEventListener(eventName, eventListener as EventListener)
    }
  }, [eventName, element, savedHandler])
}

export const useWindowSize = (): { width: number; height: number } => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })

  const handleResize = (): void => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight
    })
  }

  useEventListener('resize', handleResize)

  useEventListener('orientationchange', handleResize)

  return windowSize
}

export const useIsMobile = (size: number = MOBILE_SIZE): boolean => {
  const windowSize = useWindowSize()
  return windowSize.width <= size
}

const keysMapping: { [key: string]: number } = {
  enter: 13,
  shift: 16,
  escape: 27,
  z: 90,
  delete: 46,
  backspace: 8,
  t: 84,
  plus: 187,
  minus: 189,
  c: 67,
  v: 86,
  x: 88,
  arrowLeft: 37,
  arrowUp: 38,
  arrowRight: 39,
  arrowDown: 40
}

const checkMetaKeys = (
  configs?: { [key: string]: string | number },
  eventKeys?: { shiftKey: boolean; ctrlKey: boolean; metaKey: boolean }
) => {
  if (!configs || isEmptyObject(configs)) return true
  if (configs.shiftKey && configs.metaKey) {
    return !!(eventKeys?.shiftKey && (eventKeys.metaKey || eventKeys.ctrlKey))
  }
  if (configs.shiftKey) {
    return !!(eventKeys?.shiftKey && !(eventKeys.metaKey || eventKeys.ctrlKey))
  }
  if (configs.metaKey) {
    return !!(!eventKeys?.shiftKey && (eventKeys?.metaKey || eventKeys?.ctrlKey))
  }
  return false
}
export const useKeyPress = (
  targetKey: string,
  handler: (event: KeyboardEvent) => void,
  configs?: { [key: string]: string | number }
): void => {
  const keyDownHandler = useCallback(
    (event: KeyboardEvent) => {
      const { charCode, keyCode, shiftKey, ctrlKey, metaKey } = event
      const code = charCode || keyCode

      if (
        code === keysMapping[targetKey] &&
        checkMetaKeys(configs, { shiftKey, ctrlKey, metaKey })
      ) {
        handler(event)
      }
    },
    [targetKey, configs, handler]
  )

  useEffect(() => {
    window.addEventListener('keydown', keyDownHandler)
    return () => {
      window.removeEventListener('keydown', keyDownHandler)
    }
  }, [keyDownHandler])
}

export const useEscKeyPress = (handler: (event: KeyboardEvent) => void): void =>
  useKeyPress('escape', handler)

export const useEnterKeyPress = (handler: (event: KeyboardEvent) => void): void =>
  useKeyPress('enter', handler)
