import { createContext } from 'react'
import { LOAN_STATUSES, REPAYMENTS_MOCK_DATA } from '../consts'
import {
  TAppConfigs,
  TLoanDetails,
  TProfileInfo,
  TSelectOptions,
  TOfferData,
  TRepayment,
  TShoppingData,
  TTermsData
} from '../types'
import { noop } from '../utils'

export const UserNameContext = createContext<{
  userName: string
  setUserName: (name: string) => void
}>({
  userName: '',
  setUserName: noop
})

declare type TFormStepperContext = {
  prevHandler: () => void
  nextHandler: () => void
  activeStepIndex: number
}
export const FormStepperContext = createContext<TFormStepperContext>({
  prevHandler: noop,
  nextHandler: noop,
  activeStepIndex: 0
})

export const ProfileContext = createContext<{
  profileInfo: TProfileInfo
  updateProfileInfo: (
    data: TProfileInfo,
    id: string,
    callback?: (data: TProfileInfo) => void,
    withLoading?: boolean
  ) => void
  getProfile: (nextHandler?: (data: TProfileInfo) => void) => void
}>({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  profileInfo: {},
  updateProfileInfo: noop,
  getProfile: noop
})

export const LoadingContext = createContext<{
  isLoading: boolean
  setIsLoading: (state: boolean) => void
  message: string
  setLoadingMessage: (message: string) => void
}>({
  isLoading: false,
  setIsLoading: noop,
  message: '',
  setLoadingMessage: noop
})

export const ProvincesContext = createContext<{
  provinces: TSelectOptions
  regions: TSelectOptions
  getProvinces: () => void
  getRegions: (code: string) => void
}>({ provinces: [], regions: [], getProvinces: noop, getRegions: noop })

export const AppContext = createContext<{
  isDocumentsVerified: boolean
  setIsDocumentVerified: (isVerified: boolean) => void
  appId: string | null
  setAppId: (id: string) => void
}>({
  appId: null,
  setAppId: noop,
  isDocumentsVerified: false,
  setIsDocumentVerified: noop
})

export const LoanDetailsContext = createContext<{
  loanDetails: TLoanDetails
  setLoanDetails: (details: TLoanDetails) => void
}>({
  loanDetails: {
    preferredRepaymentDay: null,
    duration: null,
    preferredRepaymentDate: undefined
  },
  setLoanDetails: noop
})

export const CONFIGS_DEFAULT_VALUE = {
  environmentName: '',
  externalApiUrl1: '',
  identificationService: {
    authorizationUrl: '',
    partnerId: '',
    partnerKey: '',
    statusUrl: '',
    widgetUrl: ''
  }
}
export const AppConfigContext = createContext<TAppConfigs>(CONFIGS_DEFAULT_VALUE)

export type TAppStatus = 'PENDING' | 'COMPLETED' | 'REJECTED'

export const AppStatusContext = createContext<{
  status: LOAN_STATUSES
  reason: string
  setStatus: (status: LOAN_STATUSES) => void
  setReason: (status: string) => void
}>({
  reason: '',
  status: LOAN_STATUSES.new,
  setStatus: noop,
  setReason: noop
})

export const InfoPopupVisibilityContext = createContext<{
  isVisible: boolean
  closePopup: () => void
}>({ isVisible: true, closePopup: noop })

export const ErrorContext = createContext<{
  errorMessage: string
  setErrorMessage: (message: string) => void
}>({
  errorMessage: '',
  setErrorMessage: noop
})

export const ShoppingDataContext = createContext<{
  shoppingData: TShoppingData
  setShoppingData: (data: TShoppingData) => void
}>({
  shoppingData: [],
  setShoppingData: noop
})

export const ScheduleContext = createContext<{
  scheduleData: TRepayment[]
  actualInterestRate: number | null
  setScheduleData: (data: TRepayment[]) => void
  setActualInterestRate: (data: number) => void
}>({
  scheduleData: REPAYMENTS_MOCK_DATA,
  actualInterestRate: null,
  setScheduleData: noop,
  setActualInterestRate: noop
})

export const OfferContext = createContext<{ offers: TOfferData[]; setOffers: (date) => void }>({
  offers: [],
  setOffers: noop
})

export const ActiveStepContext = createContext<{
  activeStepIndex: number
  setActiveStepIndex: (step: number) => void
}>({
  activeStepIndex: 0,
  setActiveStepIndex: noop
})

export const LaonTermsContext = createContext<{
  data: TTermsData
  setData: (data: TTermsData) => void
}>({
  data: null,
  setData: () => {
    console.log('LaonTermsContext')
  }
})
