import { Button } from 'dino_ui_tools'
import { noop } from '../../utils'
import './index.scss'
import { TFormSubmitButtonProps } from './types'

const FormSubmitButton = (props: TFormSubmitButtonProps) => {
  const { onClick = noop, buttonText = 'Հաջորդ' } = props
  return (
    <div className="submit_button_container">
      <Button
        buttonText={buttonText}
        type="primary"
        buttonActionType="submit"
        onClick={onClick}
        className="submit-button"
      />
    </div>
  )
}

export default FormSubmitButton
