import { Icon, Modal, Text } from 'dino_ui_tools'
import { TErrorPopupProps } from './types'
import './index.scss'

const ErrorPopup = (props: TErrorPopupProps) => {
  const { errorMessage, closePopup } = props
  return (
    <Modal
      isOpen={!!errorMessage}
      onClose={closePopup}
      onSumbit={closePopup}
      buttonProps={{ confirm: { buttonText: 'Լավ' } }}
    >
      <div className="error_popup">
        <div className="icon-wrapper">
          <Icon name="close" size="medium" type="danger" />
        </div>
        <Text className="error_popup_message">{errorMessage}</Text>
      </div>
    </Modal>
  )
}
export default ErrorPopup
