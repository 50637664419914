import { Button, Icon, Text } from 'dino_ui_tools'
import logo from '../../assets/header_logo.svg'
import { useIsMobile } from '../../hooks/useIsMobile'
import { noop } from '../../utils'
import './index.scss'

const Header = () => {
  const isMobile = useIsMobile()

  return (
    <div className="header">
      <img className="header_logo" src={`${logo}`} alt="logo" />

      <Button
        buttonText={
          <div className="logout_button">
            <a href="/auth/logout">
              <Icon name="logout" size="small" type="secondary" />
              {!isMobile ? (
                <Text className="buttonText" size="medium">
                  Դուրս գալ
                </Text>
              ) : null}
            </a>
          </div>
        }
        type="tertiary"
        onClick={noop}
        className="submit-button"
      />
    </div>
  )
}

export default Header
