import { useRef } from 'react'
import { Text } from 'dino_ui_tools'
import { TFilledInfoProps } from './types'
import './index.scss'

const FilledInfo = (props: TFilledInfoProps) => {
  const { label, info, className, weight = 'regular' } = props
  const infoContainerRef = useRef(null)

  return (
    <div className={`${className ? className : ''} info_item_container`} ref={infoContainerRef}>
      {label ? (
        <Text size="xsmall" type="secondary" className="info_label">
          <> {label}</>
        </Text>
      ) : null}
      <Text size="medium" className="info_text" weight={weight}>
        {info}
      </Text>
    </div>
  )
}

export default FilledInfo
