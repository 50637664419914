export const AGGREEMENT =
  'https://ameriabank.am/Portals/0/files/Personal/settlement-agreement_arm.pdf'

export const MOBILE_SIZE = 480

export const LOAN_DURATION_OPTIONS = [
  { label: 12, value: '12' },
  { label: 24, value: '24' },
  { label: 36, value: '36' }
]

export enum LOAN_STATUS_TYPES {
  pending = 'preparing',
  approved = 'approved',
  completed = 'completed',
  rejected = 'rejected'
}

export enum LOAN_STATUSES {
  new = 'new',
  contactVerified = 'contacts-verified',
  documentVerified = 'documents-verified',
  identified = 'identified',
  offersPreparing = 'offers-preparing',
  rejected = 'rejected',
  offersReady = 'offers-ready',
  termsAccepted = 'terms-accepted',
  failed = 'failed',
  signaturePending = 'signature-pending',
  canceled = 'canceled',
  completed = 'completed'
}

export const LOADING_STATES = [LOAN_STATUSES.offersPreparing]
export const DECLINED_STATES = [
  LOAN_STATUSES.rejected,
  LOAN_STATUSES.failed,
  LOAN_STATUSES.canceled
]
export const DOCUMENTS_VERIFIED_STATUSES = [
  LOAN_STATUSES.identified,
  LOAN_STATUSES.offersPreparing,
  LOAN_STATUSES.offersReady,
  LOAN_STATUSES.termsAccepted
]

export enum IDENTIFICATION_STATUS_TYPES {
  approved = 'approved',
  rejected = 'rejected'
}

export enum CONTACT_VERIFICATION_STATUS_TYPES {
  failed = 'failed',
  confirmed = 'confirmed'
}

export const LOAN_DETAILS_LINK =
  'https://ameriabank.am/Portals/0/files/basic_conditions_of_installment_financing-service_arm.pdf'
export const LOAN_MAIN_DETAILS_LINK =
  'https://ameriabank.am/Portals/0/files/Personal/Consumer_finance_terms.pdf'

export enum VALIDATION_MESSAGES {
  required = 'Տվյալի լրացումը պարտադիր է',
  invalid = 'Մուտքագրված կոդը սխալ է'
}
export const OFFERS_MOCK_DATA = {
  code: '',
  currencyCode: '',
  interest: 0,
  maxAmount: 0,
  maxDuration: 0,
  minAmount: 0,
  minDuration: 0
}

export const REPAYMENTS_MOCK_DATA = [
  {
    baseAmount: 0,
    date: '',
    interestAmount: 0,
    totalAmount: 0
  }
]
// [
//   {
//     date: '2023-05-18',
//     baseAmount: 23891.8,
//     interestAmount: 6538.4,
//     totalAmount: 30430.2
//   },
// ]

export const GLOBAL_ERROR_MESSAGE = 'Տեխնիկական խնդիր'

export const LAON_APPLICATIONS_PARAM = 'new-loan-application'
export const OFFERS_PREPARING_MESSAGE = `Հարգելի հաճախորդ, կատարվում է հարցում Բանկին, Ձեր կողմից կատարված գնումը ֆինանսավորելու վերաբերյալ`
