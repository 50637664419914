import { Icon, Text } from 'dino_ui_tools'
import './index.scss'

const FormFooter = () => {
  return (
    <div className="form_footer">
      <Text>
        <a href="tel:+374 10 56 11 11" className="form_footer_anchor form_footer_phone_number">
          <div className="icon-wrapper">
            <Icon name="phone" size="small" />
          </div>
          +374 10 56 11 11
        </a>
      </Text>

      <Text>
        <a href="mailto:info@ameriabank.am" className="form_footer_anchor form_footer_email">
          <div className="icon-wrapper">
            <Icon name="mail" size="small" />
          </div>
          info@ameriabank.am
        </a>
      </Text>
    </div>
  )
}

export default FormFooter
