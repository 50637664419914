import * as yup from 'yup'
import { VALIDATION_MESSAGES } from '../../consts'

export const SUBVISION = 'subdivisionCode'
export const REGION = 'regionCode'
export const STREET = 'street'
export const BUILDING = 'building'
export const UNIT = 'unit'
const ARMENIAN_CHARS_REGEXP = /^[ Ա-Ֆա-ֆ,և0-9`+-./,։()«» ]+$/
const WRONG_LANG_ERROR = 'Հասցեն պետք է լրացնել հայատառ'

export const ADDRESS_VALIDATION_SCHEME = yup.object({
  [SUBVISION]: yup.mixed().required(VALIDATION_MESSAGES.required),
  [REGION]: yup.mixed().required(VALIDATION_MESSAGES.required),
  [STREET]: yup
    .mixed()
    .required(VALIDATION_MESSAGES.required)
    .test('valid', WRONG_LANG_ERROR, (val) => ARMENIAN_CHARS_REGEXP.test(val)),
  [BUILDING]: yup
    .mixed()
    .required(VALIDATION_MESSAGES.required)
    .test('valid', WRONG_LANG_ERROR, (val) => ARMENIAN_CHARS_REGEXP.test(val)),
  [UNIT]: yup
    .mixed()
    .test('valid', WRONG_LANG_ERROR, (val) => (val ? ARMENIAN_CHARS_REGEXP.test(val) : true))
})
