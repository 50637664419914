import { Text, Button, Heading } from 'dino_ui_tools'

import { UseIdentification } from '../../hooks/useIdentification'

import withWrapper from '../FormWrapper'
import './index.scss'

const Identification = () => {
  const { startVerification } = UseIdentification()

  return (
    <div className="identification_container">
      <Heading className="mb-24" size="xsmall" weight="bold">
        Նույնականացում
      </Heading>
      <Text className="identification_container_content">
        Խնդրում ենք կատարել անձը հաստատող փաստաթղթի նկարում և ինքնանկարում (selfie/սելֆի)
      </Text>

      <Button
        onClick={startVerification}
        buttonActionType="submit"
        buttonText="Սկսել"
        type="primary"
        className="start_identification_button"
      />
    </div>
  )
}

export default withWrapper(Identification)
