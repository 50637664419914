import React from 'react'
import { Text } from 'dino_ui_tools'
import './index.scss'

const LogoLoader = (props) => {
  const { message } = props

  return (
    <div className="loader-wrapper" id={'logo-loader'}>
      <div className="loader">
        <svg
          width="50"
          height="51"
          viewBox="0 0 30 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="loading-animation"
        >
          <path
            d="M9.78517 22.6417L15.0001 10.7509L20.215 22.6417C20.2444 22.7089 20.3109 22.7523 20.3842 22.7523H22.8685C23.0018 22.7523 23.0912 22.6154 23.0377 22.4933L17.075 8.89746C16.2821 7.09013 13.718 7.09013 12.9252 8.89769L6.96245 22.4933C6.90894 22.6154 6.99835 22.7523 7.13168 22.7523H9.61594C9.6893 22.7523 9.75574 22.7089 9.78517 22.6417"
            fill="white"
          />
          <mask id="mask0_33_10068" maskUnits="userSpaceOnUse" x="0" y="0" width="30" height="31">
            <path d="M0 0.147095H30V30.1471H0V0.147095Z" fill="white" />
          </mask>
          <g mask="url(#mask0_33_10068)">
            <path
              className="loading-animation__path"
              d="M27.1896 30.1471H2.81067C1.26085 30.1471 -1.52588e-05 28.8863 -1.52588e-05 27.3364V2.95776C-1.52588e-05 1.40795 1.26085 0.147095 2.81067 0.147095H27.1896C28.7394 0.147095 30 1.40795 30 2.95776V27.3364C30 28.8863 28.7394 30.1471 27.1896 30.1471V30.1471ZM2.81065 1.0708C1.77008 1.0708 0.92371 1.91717 0.92371 2.95773V27.3364C0.92371 28.377 1.77008 29.2233 2.81065 29.2233H27.1895C28.2299 29.2233 29.0765 28.377 29.0765 27.3364V2.95773C29.0765 1.91717 28.2299 1.0708 27.1895 1.0708H2.81065Z"
              fill="white"
            />
          </g>
        </svg>
      </div>
      {message ? (
        <Text className="loading_message" weight="semibold">
          {message}
        </Text>
      ) : null}
    </div>
  )
}

export default LogoLoader
