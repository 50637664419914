import { HttpClient } from './ApiFactory'

const baseURL = ''

class HttpService extends HttpClient {
  public constructor() {
    super(baseURL)
  }

  public async get<T>(url: string): Promise<T> {
    return this.instance.get(url)
  }

  public post<T, P>(url: string, payload: P): Promise<T> {
    return this.instance.post(url, payload)
  }

  public update<T, P>(url: string, payload: P): Promise<T> {
    return this.instance.put(url)
  }

  public delete<T, P>(url: string, payload: P): Promise<T> {
    return this.instance.delete(url)
  }
}
export { HttpService }
