import { useCallback, useContext, useEffect, useState } from 'react'
import { Input, FormContainer, FormField, Heading } from 'dino_ui_tools'
import { ActiveStepContext, LoadingContext, UserNameContext } from '../../context'
import { useProfile, useVerification } from '../../hooks'

import CheckCamerInfoPopup from '../CheckCamerInfoPopup'
import FormSubmitButton from '../FormSubmitButton'
import CodeVerification from './CodeVerification'
import withWrapper from '../FormWrapper'
import FilledInfo from '../FilledInfo'
import Label from '../Label'

import { PHONE_NUMBER, VERIFICATION_CODE } from './consts'
import { formatPhoneNumber, getPhoneNumberInfo, getValidationScheme } from './utils'
import './index.scss'
import { useParams } from 'react-router-dom'

const PersonalDetails = () => {
  const { setActiveStepIndex } = useContext(ActiveStepContext)
  const { id } = useParams()
  const { profileInfo, updateProfileInfo, getProfile } = useProfile()
  const { userName } = useContext(UserNameContext)
  const { setIsLoading } = useContext(LoadingContext)
  const { emailAddress } = profileInfo?.contactEmail || {}

  const [isCodeVerificationVisible, setIsCodeVerificationVisible] = useState(false)
  const profileFormattedPhoneNumber = formatPhoneNumber(profileInfo.contactPhone)
  const [submitedPhoneNumber, setSubmitedPhoneNumber] = useState(profileFormattedPhoneNumber)

  const INITIAL_VALUES = {
    [PHONE_NUMBER]: profileFormattedPhoneNumber,
    [VERIFICATION_CODE]: ''
  }

  const openVerificationView = (profile) => {
    setIsLoading(false)
    setIsCodeVerificationVisible(true)
  }

  const verifyPhoneNumber = (phoneNumber) => {
    openVerificationSession(phoneNumber, openVerificationView)
  }
  const { openVerificationSession, sendOtp, isVerified } = useVerification()

  const onFormSubmit = (data) => {
    const { phoneNumber, verificationCode } = data
    const { isVerified } = profileInfo?.contactPhone || { isVerified: false }

    if (isCodeVerificationVisible) {
      sendOtp(verificationCode)
    } else {
      if (phoneNumber === profileFormattedPhoneNumber && isVerified) {
        setActiveStepIndex(1)

        return
      }
      setSubmitedPhoneNumber(phoneNumber)

      const { countryCode, areaCode, number } = getPhoneNumberInfo(phoneNumber)
      const withLoading = true
      updateProfileInfo(
        {
          ...profileInfo,
          contactPhone: {
            ...profileInfo.contactPhone,
            countryCode: countryCode,
            areaCode: areaCode,
            phoneNumber: number
          }
        },
        id,
        () => verifyPhoneNumber(phoneNumber),
        withLoading
      )
    }
  }
  const sendVerificationCode = useCallback(() => {
    openVerificationSession(submitedPhoneNumber, openVerificationView)
  }, [submitedPhoneNumber, openVerificationView])

  useEffect(() => {
    if (isVerified) {
      getProfile()
      setActiveStepIndex(1)
    }
  }, [isVerified])

  return (
    <div className="personal_details_container">
      <CheckCamerInfoPopup />
      <Heading size="xsmall" weight="bold" className="mb-24">
        Գնորդի կոնտակտային տվյալներ
      </Heading>
      <div className="info_container">
        {emailAddress ? (
          <FilledInfo className="email_info" label="Էլ. հասցե" info={emailAddress} />
        ) : null}
        <FilledInfo label="Օգտատեր" info={userName} />
      </div>

      <FormContainer
        validationScheme={getValidationScheme(isCodeVerificationVisible)}
        initialValues={INITIAL_VALUES}
        onSubmit={onFormSubmit}
      >
        <>
          <div className="form_body">
            <FormField
              className="mb-24"
              isControlled
              name={PHONE_NUMBER}
              As={(props) => {
                return (
                  <Input
                    {...props}
                    label={<Label text="Հեռախոսահամար" required />}
                    mask="+374 99 999999"
                    placeholder="+374 __ ______"
                    onChange={(event) => {
                      setIsCodeVerificationVisible(false)
                      props.onChange(event)
                    }}
                  />
                )
              }}
            />

            {isCodeVerificationVisible && <CodeVerification sendAgain={sendVerificationCode} />}
          </div>
          <FormSubmitButton />
        </>
      </FormContainer>
    </div>
  )
}
export default withWrapper(PersonalDetails)
