import { useContext, useEffect, useState } from 'react'
import { Modal, Text } from 'dino_ui_tools'
import { useParams, useNavigate, useSearchParams } from 'react-router-dom'
import { IDENTIFICATION_STATUS_TYPES, LAON_APPLICATIONS_PARAM } from '../../consts'
import { ActiveStepContext, LoadingContext, ProfileContext } from '../../context'
import { TProfileInfo } from '../../types'

const useQuery = () => {
  const [searchParams] = useSearchParams()
  return Object.fromEntries([...searchParams])
}

const VerificationChecking = () => {
  const [isVerified, setIsVerified] = useState(true)

  const { id } = useParams()

  const { setActiveStepIndex } = useContext(ActiveStepContext)
  const { setIsLoading } = useContext(LoadingContext)
  const { getProfile } = useContext(ProfileContext)
  const navigate = useNavigate()

  const { status } = useQuery()

  const redirectFirstPage = () => {
    navigate(`/${LAON_APPLICATIONS_PARAM}/${id}`)
    setActiveStepIndex(0)
  }

  const redirect = () => {
    setIsLoading(false)
    navigate(`/${LAON_APPLICATIONS_PARAM}/${id}`)
    setActiveStepIndex(3)
  }

  const checkVerification = () => {
    setIsLoading(true)
    getProfile(redirect)
  }

  useEffect(() => {
    if (status === IDENTIFICATION_STATUS_TYPES.approved) {
      checkVerification()
    } else {
      setIsVerified(false)
    }
  }, [status, id])

  return (
    <>
      <Modal
        isOpen={!isVerified}
        onSumbit={redirectFirstPage}
        onClose={redirectFirstPage}
        buttonProps={{ confirm: { buttonText: 'Լավ', onClick: redirectFirstPage } }}
      >
        <div className="info-popup-container">
          <Text>Նույնականացումը չի հաջողվել</Text>
        </div>
      </Modal>
    </>
  )
}

export default VerificationChecking
