export const DOC_NUMBER_REGEXP = /^[A-Za-z0-9]*$/
export const ID_CARD_REGEXP = /^[0-9]*$/
export const MONEY_CONFIRMED = 'moneyConfirm'
export const LOAN_CONFIRMED = 'loanConfirm'
export const SOCIAL_CARD_NUMBER = 'socialSecurityNumber'
export const DOCUMENT_TYPE = 'typeCode'
export const DOCUMENT_NUMBER = 'number'
export const ARCA_CONFIRMED = 'arcaConfirmed'

export enum DOC_TYEPS {
  passport = 'PASSPORT',
  id = 'IDCARD'
}

export const ID_DATA_OPTIONS = [
  { label: 'Անձնագիր', value: DOC_TYEPS.passport },
  { label: 'ID քարտ', value: DOC_TYEPS.id }
]
