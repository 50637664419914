import { useCallback, useEffect, useRef } from 'react'
import { noop } from '../utils/index'

export const useInterval = (callBack: () => void = noop, delay: number) => {
  const savedCallBack = useRef<() => void | undefined>()
  const intervalOptions = useRef<{ id: ReturnType<typeof setInterval> }>({ id: null })

  const abort = () => {
    clearInterval(intervalOptions.current.id)
  }

  useEffect(() => {
    savedCallBack.current = callBack
  }, [callBack])

  const execute = useCallback(() => {
    callBack()
  }, [callBack])

  useEffect(() => {
    if (!delay) {
      return
    }

    intervalOptions.current.id = setInterval(execute, delay)

    return () => {
      clearInterval(intervalOptions.current.id)
    }
  }, [delay])

  return { abort }
}
