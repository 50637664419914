import { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { submitLoanTermsnApi } from '../api/api-requests'
import { LaonTermsContext, LoadingContext, LoanDetailsContext, OfferContext } from '../context'
import { useCheckAppStatus } from './useCheckAppStatus'
import { useInterval } from './useInterval'

export const useSubmitLoanterms = () => {
  const { id } = useParams()

  const { data: dataForSubmit, setData } = useContext(LaonTermsContext)
  const { offers } = useContext(OfferContext)

  const offer = offers[0]

  const { loanDetails } = useContext(LoanDetailsContext)
  const { preferredRepaymentDay, duration } = loanDetails

  const { setIsLoading, isLoading } = useContext(LoadingContext)

  const { checkLoanStatus } = useCheckAppStatus()

  const appCreationCallback = () => {
    abort()
    setIsLoading(false)
  }
  const checkAppStatus = () => {
    checkLoanStatus(appCreationCallback)
  }

  const { abort } = useInterval(checkAppStatus, isLoading ? 1000 : 0)

  const setLoanTermsForSubmit = (data) => {
    const { statementDeliveryMethod } = data
    setData({
      preferredRepaymentDay: preferredRepaymentDay,
      duration: duration,
      amount: offer.maxAmount,
      offeringId: '',
      statementDeliveryMethod: statementDeliveryMethod
    })
  }

  const submitLoanTerms = () => {
    submitLoanTermsnApi(id, dataForSubmit).then(() => setIsLoading(true))
  }

  return { submitLoanTerms, setLoanTermsForSubmit }
}
