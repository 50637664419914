import * as UILIB from 'dino_ui_tools/'
import { useMemo, useState } from 'react'
import CountDown from '../../CountDown'
import Label from '../../Label'
import { VERIFICATION_CODE } from '../consts'
import './index.scss'

const { Input, Text, Icon, FormField } = UILIB

const ONE_MINUTE = 60
const DURATION = ONE_MINUTE
declare type TCodeVerificationProps = {
  sendAgain: () => void
}

const CodeVerification = (props: TCodeVerificationProps) => {
  const { sendAgain } = props
  const [isCountDownVisible, setCountDownVisibility] = useState(true)

  const minutes = DURATION <= ONE_MINUTE ? 0 : DURATION / ONE_MINUTE
  const seconds = DURATION <= ONE_MINUTE ? DURATION : DURATION % ONE_MINUTE

  const [currentSecond, setCurrentSecond] = useState(seconds)
  const [currentMinute, setCurrentMinute] = useState(minutes)

  const formField = useMemo(() => {
    return (
      <FormField
        className="mb-24"
        isControlled
        As={(props) => (
          <Input
            {...props}
            label={<Label text="Հեռախոսահամարին ուղարկված կոդ" required />}
            mask="999999"
            placeholder="______"
          />
        )}
        name={VERIFICATION_CODE}
      />
    )
  }, [])
  return (
    <div className="code_verification_container">
      {formField}
      {isCountDownVisible ? (
        <CountDown
          currentSecond={currentSecond}
          currentMinute={currentMinute}
          setCurrentSecond={setCurrentSecond}
          setCurrentMinute={setCurrentMinute}
          onComplete={() => setCountDownVisibility(false)}
        />
      ) : null}

      <div className="code_verification_container_send_code">
        <Icon size="small" name="repeat" type="success" className="send_again_icon" />
        <Text
          className="code_verification_container_send_code_button"
          size="xsmall"
          onClick={() => {
            sendAgain()
            setCountDownVisibility(true)
            setCurrentSecond(60)
          }}
        >
          Կրկին ուղարկել
        </Text>
      </div>
    </div>
  )
}

export default CodeVerification
