import { useContext } from 'react'
import { Modal, Text } from 'dino_ui_tools'
import { InfoPopupVisibilityContext } from '../../context'
import { noop } from '../../utils'
import './index.scss'

const CheckCamerInfoPopup = () => {
  const { closePopup, isVisible } = useContext(InfoPopupVisibilityContext)

  return (
    <Modal
      isOpen={isVisible}
      onClose={closePopup}
      onSumbit={noop}
      buttonProps={{
        confirm: {
          buttonText: 'Լավ'
        }
      }}
    >
      <div>
        <Text type="primary" size="standard">
          Ուշադրություն ! Գործարքը շարունակելու համար պարտադիր է տեսախցիկի առկայությունը: Խնդրում
          ենք գործարքի ընթացքում չփոխել օգտագործվող սարքը:
        </Text>
      </div>
    </Modal>
  )
}
export default CheckCamerInfoPopup
