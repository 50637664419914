import { useContext, useMemo } from 'react'
import { Button, Progress } from 'dino_ui_tools'

import { ActiveStepContext, FormStepperContext } from '../../../context'

import './index.scss'

const STEPPER_COUNT = 6

const FormHeader = () => {
  const { prevHandler, activeStepIndex } = useContext(FormStepperContext)
  const { setActiveStepIndex } = useContext(ActiveStepContext)

  const onBack = () => {
    if (activeStepIndex == 3) {
      setActiveStepIndex(1)
      return
    }
    prevHandler()
  }

  const progressWithPercent = useMemo(() => {
    return (activeStepIndex * 100) / STEPPER_COUNT
  }, [activeStepIndex])

  return (
    <div className="form_header">
      {activeStepIndex !== 0 ? (
        <Button
          onClick={onBack}
          type="tertiary"
          buttonText="Վերադառնալ"
          className="submit_button_icon"
          iconProps={{ name: 'arrow-left', alignment: 'left' }}
        />
      ) : null}
      <Progress noText percent={progressWithPercent} size="small" />
    </div>
  )
}

export default FormHeader
