import { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { GLOBAL_ERROR_MESSAGE } from '../consts'
import { startVerificationApi } from '../api/api-requests'
import { ErrorContext, LoadingContext } from '../context'

export const UseIdentification = function () {
  const { id } = useParams()

  const { setIsLoading } = useContext(LoadingContext)
  const { setErrorMessage } = useContext(ErrorContext)

  const startVerification = () => {
    setIsLoading(true)
    startVerificationApi(id)
      .then(({ data }) => {
        const REDIRECT_URL = data

        setIsLoading(false)
        window.open(REDIRECT_URL, '_self')
      })
      .catch(() => {
        setIsLoading(false)
        setErrorMessage(GLOBAL_ERROR_MESSAGE)
      })
  }

  return { startVerification }
}
