import { Text } from 'dino_ui_tools'
import './index.scss'

const Label = (props) => {
  const { text, required, className } = props
  return (
    <Text className={`${className ? className : ''} input-label`}>
      <>
        {text}
        {required ? <span className="label_important">*</span> : null}
      </>
    </Text>
  )
}
export default Label
