import { useContext } from 'react'
import * as yup from 'yup'
import { VALIDATION_MESSAGES } from '../../consts'
import { Checkbox, FormContainer, FormField, Input, Popover, Text } from 'dino_ui_tools'
import { OFFERS_MOCK_DATA } from '../../consts'
import { OfferContext, ScheduleContext } from '../../context'
import { useProfile, useSubmitLoanterms } from '../../hooks'
import FormSubmitButton from '../FormSubmitButton'
import withWrapper from '../FormWrapper'
import {
  CONFIRMATION_INITIAL_VALUES,
  LOAN_RATE,
  AMOUNT,
  DURATION,
  CHECKBOX_CONFIRM
} from './consts'
import './index.scss'
import { getLoanFullAmount } from './utils'

const ConfirmationView = () => {
  const { profileInfo } = useProfile()
  const { name, patronymic, lastname } = profileInfo

  const { submitLoanTerms } = useSubmitLoanterms()

  const { offers } = useContext(OfferContext)
  const { scheduleData, actualInterestRate } = useContext(ScheduleContext)

  const offer = offers ? offers[0] : OFFERS_MOCK_DATA

  const { maxAmount, currencyCode } = offer

  const loanFullAmount = getLoanFullAmount(scheduleData)
  const CONFIRMATION_VALIDATION_SCHEME = yup.object({
    [LOAN_RATE]: yup
      .string()
      .required(VALIDATION_MESSAGES.required)
      .test('valid', 'անհամապատասխանություն', (val) => actualInterestRate.toString() === val),
    [AMOUNT]: yup
      .string()
      .required(VALIDATION_MESSAGES.required)
      .test('valid', 'անհամապատասխանություն', (val) => maxAmount.toString() === val),
    [DURATION]: yup
      .string()
      .required(VALIDATION_MESSAGES.required)
      .test('valid', 'անհամապատասխանություն', (val) => loanFullAmount.toString() === val),
    [CHECKBOX_CONFIRM]: yup.boolean().oneOf([true], VALIDATION_MESSAGES.required)
  })

  return (
    <div className="confirmation_container">
      <FormContainer
        initialValues={CONFIRMATION_INITIAL_VALUES}
        validationScheme={CONFIRMATION_VALIDATION_SCHEME}
        onSubmit={submitLoanTerms}
      >
        <>
          <div className="info_container">
            <Text className="big_label">
              Սույնով հաստատում եմ, որ գիտակցում եմ, որ տարեկան փաստացի տոկոսադրույքի չափը կազմում
              է`
            </Text>
            <FormField
              className="mb-24"
              isControlled
              name={LOAN_RATE}
              As={(props) => (
                <Popover position="top-left" text={`${actualInterestRate}`}>
                  <Input
                    {...props}
                    leftIconProps={{ name: 'info' }}
                    rightIconProps={{ name: 'percent' }}
                  />
                </Popover>
              )}
            />
          </div>
          <div className="info_container">
            <Text className="big_label">
              <>
                <span>Ես՝ </span>
                <Text className="name" weight="bold">{` ${name} ${patronymic} ${lastname}`}</Text>,
                վերցնելով`
              </>
            </Text>
            <FormField
              className="mb-24"
              isControlled
              name={AMOUNT}
              As={(props) => (
                <Popover position="top-left" text={`${maxAmount} ${currencyCode}`}>
                  <Input
                    {...props}
                    leftIconProps={{ name: 'info' }}
                    rightIconProps={{ name: 'dram' }}
                  />
                </Popover>
              )}
            />
          </div>
          <div className="info_container">
            <Text className="big_label">
              գումարի կրեդիտը պարտավորվում եմ սահմանված ժամկետում մարել`
            </Text>
            <FormField
              className="mb-24"
              isControlled
              name={DURATION}
              As={(props) => (
                <Popover position="top-left" text={`${loanFullAmount} դրամ`}>
                  <Input
                    {...props}
                    leftIconProps={{ name: 'info' }}
                    rightIconProps={{ name: 'dram' }}
                  />
                </Popover>
              )}
            />
          </div>
          <Text className="big_label">
            և գիտակցում եմ, որ ստանձնած պարտավորությունը ժամանակին և պատշաճ չկատարելու դեպքում
            վճարելու եմ տույժ/տուգանքներ և վատանալու է իմ վարկային պատմությունը, հնարավոր է նաև
            զրկվեմ իմ սեփական գույքից:
          </Text>

          <Text className="big_label label_hint">
            «ուղարկել Բանկ» կոճակը սեղմելով հաստատում եմ, որ իմ կողմից Բանկին ուղղված ֆինանսավորման
            ստացման օֆերտան կներկայացվի Բանկ
          </Text>

          <FormField
            className="mb-24"
            isControlled
            isNeedChangeHandler
            name={CHECKBOX_CONFIRM}
            As={(props) => (
              <Checkbox
                {...props}
                label={
                  <Text>
                    <>
                      {`Հաստատում եմ, որ ծանոթացել եմ և համաձայն եմ `}
                      <a
                        className="link"
                        href="https://ameriabank.am/Portals/0/files/General/standard_arbitration_form.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        արբիտրաժային համաձայնագրով սահմանված դրույթներին
                      </a>
                    </>
                  </Text>
                }
              />
            )}
          />
          <FormSubmitButton buttonText="Ուղարկել բանկ" />
        </>
      </FormContainer>
    </div>
  )
}

export default withWrapper(ConfirmationView)

// TODO add link
