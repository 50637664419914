export const PROVINCES_URL = '/api/localities/subdivisions'
export const REGIONS_URL = '/api/localities/regions'
export const AUTH_SESSION = '/auth/session'
export const CONTACT_VERIFICATION = '/api/contact-verifications'
export const PROFILE = '/api/profile'
export const APPLICATION_URL = '/api/applications'
export const CANDIDATE_APP_URL = '/api/candidate-applications'
export const APP_URL = '/api/applications'
export const CONFIGS_URL = '/api/config'
export const REDIRECT_API_URL = 'redirect-url'
export const SCHEDULE_URL = '/api/loan-calculators/annuity/invoke'
export const START_VERIFICATION_URL = '/public/widgets/profile-verification'
