import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios'

import { noop } from '../utils'

declare module 'axios' {}

type TExcludedFields =
  | 'request'
  | 'name'
  | 'message'
  | 'config'
  | 'code'
  | 'stack'
  | 'isAxiosError'
  | 'toJSON'

type TErrorHandlerArguments = Omit<AxiosError, TExcludedFields>

interface IErrorHandler {
  (args: TErrorHandlerArguments): void
}
export abstract class HttpClient {
  protected readonly instance: AxiosInstance
  protected errorHandlerCallBack: IErrorHandler

  public constructor(baseURL: string, errorHandler?: IErrorHandler) {
    this.instance = axios.create({
      baseURL
    })

    this.errorHandlerCallBack = errorHandler || noop

    this.initializeResponseInterceptor()
  }

  private initializeResponseInterceptor(): void {
    this.instance.interceptors.response.use(this.handleResponse, this.handleError.bind(this))

    this.instance.interceptors.request.use(this.handleRequest.bind(this))
  }

  private async handleRequest(config: AxiosRequestConfig) {
    const requestConfig = { ...config, headers: config.headers ?? {} }

    return requestConfig
  }

  private handleResponse(response: AxiosResponse<any, any>): AxiosResponse {
    return response
  }

  protected handleError(error: AxiosError): any {
    this.errorHandlerCallBack?.({ response: error.response })

    return Promise.reject(error)
  }
}
