import { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { getAppOfferApi } from '../api/api-requests'
import { OfferContext } from '../context'

export const useGetAppOffer = () => {
  const { setOffers } = useContext(OfferContext)

  const { id } = useParams()
  const getAppOffers = (cb?: () => void) => {
    getAppOfferApi(id).then(({ data }) => {
      setOffers(data)
      if (cb) {
        cb()
      }
    })
  }

  return { getAppOffers }
}
