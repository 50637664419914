import { useContext, useState } from 'react'
import { openVerificationSessionApi, sendOtpApi } from '../api/api-requests'
import { CONTACT_VERIFICATION_STATUS_TYPES } from '../consts'
import { ErrorContext } from '../context'
import { TSessionVerificationResult, TSessionState } from '../types'

export const useVerification = () => {
  const [sessionId, setSessionId] = useState(null)
  const [isVerified, setIsverified] = useState(false)
  const { setErrorMessage } = useContext(ErrorContext)
  const openVerificationSession = (phoneNumber, onSuccess) => {
    openVerificationSessionApi(phoneNumber).then(
      ({ data }: { data: TSessionVerificationResult }) => {
        const { id } = data
        setSessionId(id)
        onSuccess()
      }
    )
  }

  const sendOtp = (otp: string) => {
    sendOtpApi(sessionId, otp).then(({ data }: { data: TSessionState }) => {
      const { status } = data
      if (status === CONTACT_VERIFICATION_STATUS_TYPES.failed) {
        setErrorMessage('Մուտքագրված կոդը սխալ է։')
      }
      const isConfirmed = status === CONTACT_VERIFICATION_STATUS_TYPES.confirmed
      setIsverified(isConfirmed)
    })
  }

  return { isVerified, sessionId, openVerificationSession, sendOtp }
}
