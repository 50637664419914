import { TProfileInfo } from '../types'
import {
  PROVINCES_URL,
  REGIONS_URL,
  AUTH_SESSION,
  CONTACT_VERIFICATION,
  PROFILE,
  CANDIDATE_APP_URL,
  APP_URL,
  CONFIGS_URL,
  APPLICATION_URL,
  REDIRECT_API_URL,
  SCHEDULE_URL,
  START_VERIFICATION_URL
} from './consts'
import { HttpService } from './index'

export const getProfileApi = (): Promise<any> => {
  const httpService = new HttpService()
  return httpService.get(PROFILE)
}

export const getSession = (): Promise<any> => {
  const httpService = new HttpService()
  return httpService.get(AUTH_SESSION)
}

export const getProvincesApi = (): Promise<any> => {
  const httpService = new HttpService()
  return httpService.get(`${PROVINCES_URL}?country=am`)
}

export const getRegionsApi = (provinceCode: string): Promise<any> => {
  const httpService = new HttpService()
  return httpService.get(`${REGIONS_URL}?subdivisoin=${provinceCode}`)
}

export const openVerificationSessionApi = (contact: string): Promise<any> => {
  const httpService = new HttpService()
  return httpService.post(CONTACT_VERIFICATION, { contactType: 'phone', contact })
}

export const sendOtpApi = (id: string, verificationCode: string): Promise<any> => {
  const httpService = new HttpService()
  return httpService.post(`${CONTACT_VERIFICATION}/${id}/confirmation`, { verificationCode })
}

export const updateProfileInfoApi = (appId: string, profile: TProfileInfo) => {
  const httpService = new HttpService()
  return httpService.post(`${PROFILE}?appId=${appId}`, profile)
}

export const startVerificationApi = (candAppId: string): Promise<any> => {
  const httpService = new HttpService()
  return httpService.post(`${START_VERIFICATION_URL}/${candAppId}/start`, {})
}

export const submitCandidateApplicationApi = (id: string): Promise<any> => {
  const httpService = new HttpService()
  return httpService.post(`${CANDIDATE_APP_URL}/${id}/submit`, {})
}

export const submitLoanTermsnApi = (id: string, payload): Promise<any> => {
  const httpService = new HttpService()
  return httpService.post(`${APPLICATION_URL}/${id}/terms`, payload)
}

export const checkAppStatusApi = (id: string): Promise<any> => {
  const httpService = new HttpService()
  return httpService.get(`${APP_URL}/${id}`)
}

export const getAppOfferApi = (appId: string): Promise<any> => {
  const httpService = new HttpService()
  return httpService.get(`${APP_URL}/${appId}/offers`)
}

export const getAppConfigsApi = (): Promise<any> => {
  const httpService = new HttpService()
  return httpService.get(CONFIGS_URL)
}

export const getShoppingDetailsApi = (appId): Promise<any> => {
  const httpService = new HttpService()
  return httpService.get(`${APPLICATION_URL}/${appId}/opaque`)
}

export const getRedirectUrlApi = (appId: string): Promise<any> => {
  const httpService = new HttpService()
  return httpService.get(`${APPLICATION_URL}/${appId}/${REDIRECT_API_URL}`)
}
export const setSceduleDataApi = (payload): Promise<any> => {
  const httpService = new HttpService()
  return httpService.post(SCHEDULE_URL, payload)
}
