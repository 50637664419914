import { useContext, useEffect, useMemo, useState } from 'react'
import { FormField, Select, SimpleDatePicker } from 'dino_ui_tools'
import { LoanDetailsContext, OfferContext, ScheduleContext } from '../../context'
import { LOAN_DURATION_OPTIONS, OFFERS_MOCK_DATA } from '../../consts'
import { useGetAppOffer, useGetScedule } from '../../hooks'
import { LOAN_DURATION, PAYMENT_DAY } from './consts'
import { getAllMonthsLastdays } from './utils'
import Label from '../Label'
import './index.scss'

const LoanDetails = () => {
  const { getAppOffers } = useGetAppOffer()

  const { loanDetails } = useContext(LoanDetailsContext)
  const { scheduleData } = useContext(ScheduleContext)
  const { totalAmount } = scheduleData[0]

  const { preferredRepaymentDate, duration } = loanDetails

  const initialTerm =
    LOAN_DURATION_OPTIONS.find(
      (loanDuration) => duration && loanDuration.value === duration.toString()
    ) || LOAN_DURATION_OPTIONS[0]

  const [currentData, setData] = useState<{ date?: Date; term?: number | string }>({
    date: preferredRepaymentDate,
    term: initialTerm.value
  })

  const { setSceduleData } = useGetScedule()

  const getPerMonthFee = (terms) => {
    const { date, term } = terms
    setSceduleData({
      preferredRepaymentDay: date.getDate(),
      preferredRepaymentDate: date,
      duration: term
    })
  }

  const onTermSelect = (selectedTerm) => {
    const { date } = currentData
    if (date) {
      getPerMonthFee({ term: selectedTerm, date })
    }
    setData({ ...currentData, term: selectedTerm })
  }

  const onDurationSelect = (date) => {
    const { term } = currentData
    if (term && !totalAmount) {
      getPerMonthFee({ term, date })
    }

    setData({ ...currentData, date })
  }

  const { offers } = useContext(OfferContext)

  const offer = offers ? offers[0] || OFFERS_MOCK_DATA : OFFERS_MOCK_DATA // TODO maybe we need to remove this mock data

  const { minDuration, maxDuration } = offer

  const checkedDurationData = useMemo(() => {
    return LOAN_DURATION_OPTIONS.filter((duration) => {
      const selectedDuration = parseInt(duration.value)
      return selectedDuration >= minDuration && selectedDuration <= maxDuration
    })
  }, [minDuration, maxDuration])

  useEffect(() => {
    if (offers.length === 0) {
      getAppOffers()
    }
  }, [])

  return (
    <>
      <FormField
        className="mb-24"
        name={PAYMENT_DAY}
        isControlled
        isNeedChangeHandler
        As={(props) => (
          <SimpleDatePicker
            label="Ամենամսյա վճարման օր"
            format="DD"
            excludeDates={getAllMonthsLastdays()}
            minDate={new Date(new Date().setDate(new Date().getDate() + 15))}
            maxDate={new Date(new Date().setDate(new Date().getDate() + 45))}
            changeHandler={onDurationSelect}
            {...props}
          />
        )}
      />
      <FormField
        className="mb-24"
        name={LOAN_DURATION}
        isControlled
        isNeedChangeHandler
        As={(props) => (
          <Select
            {...props}
            isRequiredField
            label="Վարկի ժամկետ"
            options={checkedDurationData}
            setSelectedItem={onTermSelect}
          />
        )}
      />
    </>
  )
}

export default LoanDetails
