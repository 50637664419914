import * as yup from 'yup'

import {
  VERIFICATION_VALIDATION,
  VERIFICATION_CODE,
  PHONE_NUMBER,
  PHONE_NUMBER_VALIDATION_SCHEME
} from './consts'

export const getValidationScheme = (isPhoneNumberTyped: boolean) => {
  if (isPhoneNumberTyped) {
    return yup.object({
      [VERIFICATION_CODE]: VERIFICATION_VALIDATION,
      [PHONE_NUMBER]: PHONE_NUMBER_VALIDATION_SCHEME
    })
  }
  return yup.object({
    [PHONE_NUMBER]: PHONE_NUMBER_VALIDATION_SCHEME
  })
}

export const formatPhoneNumber = (phoneNumberInfo) => {
  const { countryCode, areaCode, phoneNumber } = phoneNumberInfo || {}
  if (!areaCode || !countryCode || !phoneNumber) {
    return ''
  }
  return `${countryCode} ${areaCode} ${phoneNumber}`
}

export const getPhoneNumberInfo = (phoneNumber: string) => {
  const items = phoneNumber.split(' ')
  return {
    countryCode: items[0],
    areaCode: items[1],
    number: phoneNumber.replace(`${items[0]} ${items[1]} `, '')
  }
}
